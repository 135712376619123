import PropTypes from 'prop-types'
import React from 'react'
import p1 from '../images/p1.jpg'
import B1 from '../images/B1.jpg'
import B2 from '../images/B2.jpg'
import C2 from '../images/C2.jpg'
import C3 from '../images/C3.jpg'
import Gordes from '../images/Gordes.jpg'
import K1 from '../images/K1.jpg'
import prairie from '../images/prairie.jpg'
import prairie2 from '../images/prairie2.jpg'
import MB from '../images/MB.jpg'
import MB1 from '../images/MB1.jpg'
import piscine from '../images/piscine.jpg'
import salon from '../images/salon.jpg'
import bureau from '../images/bureau.jpg'
import studio from '../images/studio.jpg'
import SB from '../images/SB.jpg'
import p2 from '../images/p2.jpg'
import p3 from '../images/p3.jpg'
import closed from '../images/closed.jpg'
import marche from '../images/marche.jpg'
import velo from '../images/velo.jpg'
import Top from '../images/Top.jpeg'
import allemand from '../images/allemand.jpg'
import anglais from '../images/anglais.jpg'
import f1 from '../images/f1.jpg'
import f2 from '../images/f2.jpg'
import f3 from '../images/f3.jpg'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major"> maison</h2>
          <p>Trois chambres et trois salles de bains plus studio séparé</p>
          <span className="image main">
          <img src={K1} alt="" />
          </span>
          <p>
            
          </p>
          <span className="image main">
          <img src={p1} alt="" />
          </span>

          <span className="image main">
          <img src={C2} alt="" />
          </span>

          <span className="image main">
          <img src={B1} alt="" />
          </span>

          <span className="image main">
          <img src={C3} alt="" />
          </span>
         
          <span className="image main">
          <img src={B2} alt="" />
          </span>

          <span className="image main">
          <img src={MB} alt="" />
          </span>
         
          <span className="image main">
          <img src={MB1} alt="" />
          </span>

          <span className="image main">
          <img src={salon} alt="" />
          </span>

          <span className="image main">
          <img src={bureau} alt="" />
          </span>

          <span className="image main">
          <img src={studio} alt="" />
          </span>

          <span className="image main">
          <img src={SB} alt="" />
          </span>
         
          <span className="image main">
          <img src={prairie} alt="" />
          </span>

          <span className="image main">
          <img src={prairie2} alt="" />
          </span>

          <span className="image main">
          <img src={p2} alt="" />
          </span>

          <span className="image main">
          <img src={piscine} alt="" />
          </span>

          <span className="image main">
          <img src={p3} alt="" />
          </span>      

          <span className="image main">
          <img src={closed} alt="" />
          </span>
         
          <p>
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">région</h2>
        
          <span className="image main">       
          <img src={Gordes} alt="" />    
          </span>

          <span className="image main">       
          <img src={velo} alt="" />    
          </span>
   
          <span className="image main">       
          <img src={marche} alt="" />    
          </span>

          <iframe width="560" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%20+(beaumettes)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 

          <p>
          </p>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">sur nous</h2>
         
          <span className="image main">
          <img src={Top} alt="" />         
          </span>

          <p>
          </p>

          <p>
          Bonjour! Nous sommes Maryse, Remy, Pamela et Raphael, les propriétaires de Le Mas Les Bidous. Bien ensoleillée dans le Luberon, c’est une échappée incontournable pour nous, où nous aimons nous promener parmi cent-cinquante oliviers, faire du vélo, jouer a la pétanque, et nous détendre à côté de la piscine. Nous espérons que vous apprécieriez la tranquillité et l'air aromatisé de la Provence.
          </p>

          <h2 className="major">Livre des revues</h2>

          <span className="image main">       
          <img src={anglais} alt="" />    
          </span>

          <span className="image main">       
          <img src={allemand} alt="" />    
          </span>
   
          <span className="image main">       
          <img src={f1} alt="" />  
          </span>
   
          <span className="image main">       
          <img src={f2} alt="" />    
          </span>
   
          <span className="image main">       
          <img src={f3} alt="" />  
          </span>
   

          {close}

        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contacter</h2>
          <form method="post" action="https://getform.io/f/1d6205cb-0773-4543-af69-7d2eb337d01d">
            <div className="field half first">
              <label htmlFor="name">Nombre</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
